import React from 'react';

import AnvsSharedExampler from './AnvsSharedExampler';

import * as Images from "./AllImage"
import {Posterize} from "../Filters/Posterize"
import { RanvsLayer } from '../../../anvs/Anvs/Core';

// tslint:disable-next-line:no-var-requires
require("../pages/index.less");

interface MultipleHeadExampleProps extends React.HTMLProps<HTMLDivElement> {
    numberOfHeads: number
}

interface MultipleHeadExampleState extends React.HTMLProps<HTMLDivElement> {
    numberOfHeads: number
}

// https://www.gatsbyjs.com/docs/using-client-side-only-packages/
const AnvsExampleLazy = React.lazy(() =>
    import("./AnvsSharedExampler")
)

export default class MultipleHeadExample extends React.Component<MultipleHeadExampleProps, MultipleHeadExampleState> {

    constructor(props) {
        super(props);
        this.state = {numberOfHeads: this.props.numberOfHeads}
    }

    layers: RanvsLayer.GroupLayer = {
        layers: [
            {image: {pool: Images.Eyes}, layout: {anchor: 0, offset: 0, size: [40, 27]}},
            {image: {pool: Images.Eyes}, layout: {anchor: [1, 0], offset: [1, 0], scale:[-1, 1], size: [40, 27]}},
            {image: {pool: Images.Mouthes}, layout: {anchor: [0.5, 1], scale: [1, {pool: [1, -1]}], offset: [0.5, 1], size: [80, 27]}},
            {image: {pool: Images.Noses,}, layout: {size: [40, 27],}},
        ],
        shaders: [Posterize.AnvsShader(1.9)]
    }

    seed = Date.now()
    load() {
        if(!this.can) return;
        setTimeout(() => {
            this.heads.forEach(p => {
                p.current?.load(this.layers, this.seed++)
            });
            this.load();
        }, 500);
    }

    can = true;
    canLoad() {
        if(!this.can) {

            this.can = true;
            this.load();
        }
    }

    stopLoad() {
        this.can = false;
    }

    more(more: number) {
        this.setState({numberOfHeads: this.state.numberOfHeads + more})
    }
    
    public render() {
        const isSSR = typeof window === "undefined"

        const heads = []
        for(var i = 0; i<this.state.numberOfHeads; i++) {
            heads.push(React.createRef<AnvsSharedExampler>(),)
        }

        var anvses = []
        heads.forEach((p,i) => {
            anvses.push(
                <AnvsExampleLazy className={"anvs"} style={{}} ref={p} key={"head"+i} seed={this.seed++} layers={this.layers} />
            )
        });
        // onMouseEnter={()=>{this.canLoad();}} onMouseLeave={()=>{this.stopLoad();}} 
        return <div className={this.props.className}>
            {!isSSR && <React.Suspense key="SUSSY" fallback={<div key="SQUARE" className={"square"} />}>
                {anvses}
            </React.Suspense>}
        </div>
    }
}