import React from 'react';
import {Prism as SyntaxHighlighter} from 'react-syntax-highlighter';
import { dracula } from 'react-syntax-highlighter/dist/esm/styles/prism';

import { BasePage } from '../components/BasePage';
import { ContentCard } from '../components/ContentCard';

import anus from '../images/anus.svg' 
import heart from '../images/heart.svg' 
import star from '../images/star.svg' 
import pentagram from '../images/satan.svg' 

import * as Images from "../components/AllImage"
import {Posterize} from "../Filters/Posterize"
// import { AnvsMask, RanvsLayer } from '../../../anvs/Anvs/Core';

import MultipleHeadExample from '../components/MultipleHeadExample';

import { AnvsReactLazy } from '../../../anvs/AnvsReactLazy';
import { AnvsImage, Ranvs, RanvsLayer } from '../../../anvs/Anvs/Core';
import { GalleryApps } from './gallery';


// tslint:disable-next-line:no-var-requires
require("./index.less");

interface HomePageProps extends React.HTMLProps<HTMLDivElement> { }
interface HomePageState extends React.HTMLProps<HTMLDivElement> {
    seed: number,
}

const usage = `import * as Anvs from 'anvs';

...

const anvs = new Anvs.GL({
    size: {x: 100, y: 100}
});

// A layer that's just a string is an image 
// without any options!
const anvsLayer: AnvsLayer = { ... /* to draw */ }

anvs.load(anvsLayer);

...

// use the rendered canvas
anvs.canvas`

const react = `import { AnvsReact } from 'anvs';

...

// You can also use a lazy version (React.Suspense)
// <AnvsReact.LazyGL
<AnvsReact.GL 
    // Needs the alpha digits, in this case it's black
    backgroundColor={0x000000FF} 

    // The size that's rendered to the canvas
    size={{x: 100, y: 100}} 
    // The size images are based on 
    //   - a 100x300px image will be 1 / 3 of the viewport
    referenceSize={{x: 300, y: 300}} 

    antialias={false}

    // Framerate when there are updateable items
    updateFps={30}

    // Defaults to device pixel scale 
    //   - could be very high resolution
    resolutionScale={1} 

    // If true, use your own webgl context / canvas
    // If false, use a shared webgl context which is 
    // rendered again to a separate canvas
    dedicatedRenderer={false} 

    // Optional, will draw immediately.
    //   - If you want access to the anvs renderer, then  
    //     use a react ref
    layer={anAnvsLayer}
    seed={0}
/> 
`

const reactSmall = `import { AnvsReact } from 'anvs';

...

// You can also use a lazy version (React.Suspense)
// <AnvsReact.LazyGL
<AnvsReact.GL 
    backgroundColor={"red"} 
    // The size that's rendered to the canvas
    size={{x: 100, y: 100}} 

    // Optional, will draw immediately.
    //   - If you want access to the anvs renderer, then  
    //     use a react ref
    layer={ ... }
    seed={0}
/> 
`

const basic = `import heart from '<folder>/heart.svg' 
import star from '<folder>/star.svg' 
import pentagram from '<folder>/pentagram.png' 

...

// A layer that's just a string is an image 
// without any options!
const layers: AnvsLayer = {
    layers: [
        heart,
        {image: {src: star, tint: "yellow"}},
        {image: {src: pentagram, tint: "red"}},
    ]
}`

const lessBasic = `// Group 1
//   - a heart with a pentagram in it
//     - the pentagram is positioned at 0,0 in the heart
//     - rotated 180 degrees
//   - heart has 0.5 scale, and positioned at the bottom right of the canvas
const redPentagramLayer = {image: {src: pentagram, tint: "red"}, layout: {offset: 0, rotation: 180}};
const heartLayer = {
    layers: [ redPentagramLayer ],
    image: {src: star, tint: "pink"},
    layout: {anchor: 1, offset: 1, scale: 0.5}
}

// Group 2
//   - a star with a pentagram in it
//     - the pentagram is using default layout, it is centered in the star
//   - star has 0.25 x scale, and is offset 0.25x 0y, anchored to it's 
//     top left corner
const bluePentagramLayer = {image: {src: pentagram, tint: "blue"}};
const starLayer = {
    layers: [ bluePentagramLayer ],
    image: {src: star, tint: "yellow"},
    layout: {anchor: 0, offset: [0.25, 0], scale: {x: 0.25}}
}

const anvsLayers = {
    layers: [ heartLayer, starLayer ],
}`

const ranvsExample = `const randomShape = {
    image: {
        src: {pool: [heart, star, pentagram]},
        tint: {color: "red", saturation: {range: [0.4, 0.9]}, hue: {range: [0, 360]}}
    },
    layout: {
        offset: {x: {range:[0, 1], snap: 0.1}, y: {range:[0, 1], snap: 0.1},},
        scale: {range:[0.5, 1], snap: 0.1},
        rotation: {range: [-45, 45], snap: 45}
    },
}

const finalAnvsLayer = {
    layers: new Array(20).fill(randomizeShape) // randomize 20 shapes
}`

const fib = `var depth = 1;
var fibA = 1; var fibB = 1;

const fibAnvs = {
    layers: [
        { // return our own layer 15 times
            get: () => { 
                const old = fibB; fibB = fibA + fibB; fibA = old; 
                return depth++ < 15 ? fibAnvs : null
            }
        }
    ],
    image:  anus,
    layout: {
        offset: {x: 1.5, y: 0.5},
        rotation: -90,
        size: {get: () => { return  10 * fibA; } },
    },
}`

const fibUpdate = `var depth = 1;
var fibA = 1; var fibB = 1;

const fibAnvs = {
    layers: [
        { // return our own layer 15 times
            get: () => { 
                const old = fibB; fibB = fibA + fibB; fibA = old; 
                return depth++ < 15 ? fibAnvs : null
            }
        }
    ],
    image: {
        src: anus, 
        tint: {color: "red", hue: {range: [0, 360]}}, 
        blendMode: AnvsImage.GLBlendMode.ADD
    },
    layout: {
        offset: {x: 1.5, y: 0.5},
        rotation: -90,
        size: {get: () => { return  10 * fibA; } },
    },
    update: (o, info) => {
        const amount = 0.25 * info.frame.deltaTime;
        o.layout.rotation += amount;
    }
}`


export default class HomePage extends React.Component<HomePageProps, HomePageState> {

    constructor(props) {
        super(props);
        this.state = {seed: Date.now()}
    }

    anus_face: RanvsLayer.GroupLayer = {
        layers: [
            {image: {pool: Images.Eyes}, layout: {anchor: 0, offset: 0, size: [40, 27]}},
            {image: {pool: Images.Eyes}, layout: {anchor: [1, 0], offset: [1, 0], scale:[{pool: [-1, 1]}, 1], size: [40, 27]}},
            {image: {pool: Images.Mouthes}, layout: {anchor: [0.5, 1], scale: [1, {pool: [1, -1]}], offset: [0.5, 1], size: [80, 27]}},
            {image: {pool: Images.Noses,}, layout: {size: [40, 27],}},
            {image: {src: anus, tint:{pool: ["black",]}}, layout: {size: [75, 75],}}
        ],
        image: anus,
        // mask: {src: anus},
        layout: {size: [80, 80]},
        shaders: [Posterize.AnvsShader(1.9)]
    }

    face: RanvsLayer.GroupLayer = {
        layers: [
            {image: {pool: Images.Eyes}, layout: {anchor: 0, offset: 0, size: [40, 27]}},
            {image: {pool: Images.Eyes}, layout: {anchor: [1, 0], offset: [1, 0], scale:[{pool: [-1, 1]}, 1], size: [40, 27]}},
            {image: {pool: Images.Mouthes}, layout: {anchor: [0.5, 1], scale: [1, {pool: [1, -1]}], offset: [0.5, 1], size: [{range: [50, 80]}, 27]}},
            {image: {pool: Images.Noses,}, layout: {size: [40, 27],}},
        ],
        shaders: [Posterize.AnvsShader(1.9)]
    }

    recurse() {
        const a: RanvsLayer = {
            layers: [
                {pool: [{layers:[]}, {get:()=>{return a}}]}
            ],
            image: {src: pentagram, tint: {color: "red", hue: {range: [0,360]}},},
            layout: {
                offset: [0.5, {range: [0.5, 0.6]}],
                scale: 0.9,
                anchor: [0.5, 0.05],
                rotation: {range: [-45, 45]},
            }
        }
        return {
            layers: [a],
            layout: {offset: [0.5, 0.1]}
        };
    }

    body: RanvsLayer.GroupLayer = {
        layers: [
            {image: {pool: Images.Eyes}, layout: {anchor: 0, offset: 0, size: [40, 27]}},
            {image: {pool: Images.Eyes}, layout: {anchor: [1, 0], offset: [1, 0], scale:[-1, 1], size: [40, 27]}},
            {image: {pool: Images.Mouthes}, layout: {anchor: [0.5, 1], scale: [1, {pool: [1, -1]}], offset: [0.5, 1], size: [80, 27]}},
            {image: {pool: Images.Noses,}, layout: {size: [40, 27],}},
        ],
        layout: {
            scale: {
                x: {range: [0.7, 1.3]},
            },
        }
    }

    basic: RanvsLayer.GroupLayer = {
        layers: [
            anus,
            {image: {src: star, tint: "yellow"}},
            {image: {src: pentagram, tint: "red"}},
        ],
    }

    get lessBasic(): RanvsLayer.GroupLayer  {
        const bluePentagramLayer = {image: {src: pentagram, tint: "blue"}};
        const starLayer = {
            layers: [ bluePentagramLayer ],
            image: {src: star, tint: "yellow"},
            layout: {anchor: 0, offset: [0.25, 0], scale: {x: 0.25}}
        }
        const redPentagramLayer = {image: {src: pentagram, tint: "red"}, layout: {offset: 0, rotation: 180}};
        const heartLayer = {
            layers: [ redPentagramLayer ],
            image: {src: heart, tint: "pink"},
            layout: {anchor: 1, offset: 1, scale: 0.5}
        }
        const anvsLayers = {
            layers: [ starLayer, heartLayer ],
        }
        return anvsLayers;
    }

    ranvsExample = () => { 
        const randomizeShape = {
            image: {
                src: {pool: [heart, star, anus]},
                tint: {color: "red", saturation: {range: [0.4, 0.9]}, hue: {range: [0, 360]}}
            },
            layout: {
                offset: {x: {range:[0, 1], snap: 0.1}, y: {range:[0, 1], snap: 0.1},},
                scale: {range:[0.5, 1], snap: 0.1},
                rotation: {range: [-45, 45], snap: 45}
            },
        }

        return {
            layers: new Array(20).fill(randomizeShape) // randomize 20 shapes
        }
    }

    fib = (animate: boolean): RanvsLayer.GroupLayer => {
        var depth = 1;

        var fibA = 1; var fibB = 1;

        const fib = {
            layers: [
                { // return our own layer 15 times
                    get: () => { 
                        const old = fibB; fibB = fibA + fibB; fibA = old; 
                        return depth++ < 15 ? fib : {layers: []} 
                    }
                }
            ],
            image: {src: anus, tint: animate ? {color: "red", hue: {range: [0, 360]}} : null, blendMode: animate ? AnvsImage.GLBlendMode.ADD : null},
            layout: {
                offset: {x: 1.5, y: 0},
                rotation: -90,
                size: {get: () => { return  10 * fibA; } },
            },
            update: !animate ? null : (o, info) => {
                const amount = info.frame.deltaTime * 0.25;
                o.layout.rotation += amount;
            }
        }

        return {
            layers: [
                fib
            ],
            layout: {
                size: 0,
                rotation: 90,
            },
        }
    }

    seed = Date.now()

    _key = 0
    get key () {
        return "INDEXKEY" + (this._key++).toString()
    }
    
    public render() {
        this._key = 0;
        const loading = <div key="loading" className="canvasWaiter"> loading... </div>

        const anusRef = React.createRef<AnvsReactLazy.GL>();
        
        var ranvsSeed = 0
        const ranvsExampleRef = React.createRef<AnvsReactLazy.GL>();

        const randomizeButton = <button key={this.key} style={{bottom: "10px", marginTop: "-34px", position: "relative"}} className='' onClick={()=>{
            this.seed = Math.abs(Ranvs.Generator.Default.reseed(this.seed).generate())
            // inputRef.current.value = this.seed.toString();
            ranvsExampleRef?.current?.anvs.load(this.ranvsExample(), this.seed++)
        }}> randomize </button>

        const ranvsExampleAnvs = <AnvsReactLazy.GL 
            placeholder={loading}
            key={this.key}
            ref={ranvsExampleRef} size={{x: 800, y: 500}} seed={this.seed++} 
            style={{maxWidth: "600px", marginTop: "10px", borderRadius: "8px", }} 
            layer={this.ranvsExample()} 
            className={"centered anvs"} 
            children={[randomizeButton]} 
        />
        
        const changeSeed = () => {
            setTimeout(() => {
                ranvsSeed = Math.abs(Ranvs.Generator.Default.reseed(ranvsSeed).generate())
                anusRef?.current?.anvs.load(this.anus_face, ranvsSeed++)
                changeSeed();
            }, 300);
        }
        changeSeed();

        const inputRef = React.createRef<HTMLInputElement>();
        const seedInput =  <form key={this.key} placeholder='seed'> 
            <input 
                key={this.key} 
                ref={inputRef} 
                type="number" 
                placeholder='seed'
                onChange={ (v) => {
                    ranvsSeed = v.target.value ?? 0;
                    ranvsExampleRef?.current?.anvs.load(this.ranvsExample, ranvsSeed++)
                }}
            /> 
        </form>

        const moreRef = React.createRef<MultipleHeadExample>();
        const showMeMoreHeads = <button key={this.key} style={{marginTop:"-50px", position: "relative"}} className='centered' onClick={()=>{
            moreRef?.current?.more(5)
        }}> show more </button>

        const AnvsTest = (layers) => {
            return <AnvsReactLazy.GL placeholder={loading} key={this.key} size={{x: 800, y: 500}} referenceSize={{x: 800, y: 500}} seed={this.seed++} layer={layers} style={{maxWidth: "600px", marginTop: "10px", borderRadius: "8px",}} className={"centered anvs"} />
        }

        const collapse = (num) => {return {numberVisibleWhenClosed: num, collapseText: "hide code", expandText: "show code"}}

        return <BasePage key="Main Page" children={[
            <div key={this.key} className="SplitPage" >
                <div key={this.key} className='contentBackground' >
                    <div key={this.key} className='' >


                        {/* <div className='split'>
                            <ContentCard style={{display: "flex", alignItems: "center", gap: "10px"}} children={[
                                {type: "Title", content: "anvs"},
                                {type: "Note", content: "(anvas, an·vuhs, anus)"},
                            ]} />
                        </div> */}

                        <div key={this.key} style={{marginTop: "0px"}} className='section'>
                            <ContentCard key={this.key} style={{textAlign:"center"}} children={[
                                {type: "Heading1", content: "simple layered canvas generation for js"},
                                {type: "Text", content: <p>with fast, easy, powerful randomization </p>},
                                <AnvsReactLazy.GL 
                                    key={this.key}
                                    ref={anusRef}
                                    size={{x: 300, y: 300}} 
                                    resolutionScale={2}
                                    backgroundColor={0x868686FF} 
                                    referenceSize={{x: 80, y: 80}} 
                                    seed={this.seed++} 
                                    antialias={true}
                                    dedicatedRenderer={true}
                                    layer={this.anus_face} 
                                    style={{ maxWidth: "600px", marginTop: "10px", marginBottom: "10px", borderColor:"transparent", borderWidth: "0px"}} 
                                    className={"centered anvs"} 
                                    placeholder={loading}
                                />,
                                // <img style={{ width: "50%", marginTop: "-1px", transform: "scaleY(-1)"}}  className="centered" src={poo} />,
                                {type: "Note", content: <p>webgl / html5 canvas | built on top of <a href="https://pixijs.com/" >pixi.js</a></p>},
                            ]} />

                        </div>


                        <div className='separator' />

                        <ContentCard key={this.key} className='section'  style={{textAlign:"center"}} children={[
                            {type: "Heading1", content: "apps using anvs"},
                            GalleryApps()
                        ]} />

                        <ContentCard key={this.key} className='section' style={{textAlign:"center"}} children={[
                            <button className="bigButton" key={this.key} onClick={ () => { location.href='/gallery';} } > see more </button>,
                        ]} />

                        <div className='separator' />


                        <ContentCard key={this.key} className="section" collapsable={collapse(3)} style={{textAlign:"center", marginTop:"70px"}} children={[

                            {type: "Heading1", content: "layers of layers of images"},
                            {type: "Note", content: "at it's most basic, anvs loads and stacks images on top of each other"},
                            AnvsTest(this.basic),
                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={basic}  />
                            </div>

                        ]} />


                        <ContentCard key={this.key} className="section" collapsable={collapse(3)} style={{textAlign:"center", flex: "0"}} children={[


                            {type: "Heading1", content: "*relatively* more complex example"},
                            {type: "Note", content: "anvs layers can be positioned and sized relative to their parent"},

                            AnvsTest(this.lessBasic),

                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={lessBasic}  />
                            </div>

                        ]} />


                        <ContentCard key={this.key} className="section" collapsable={collapse(3)} style={{textAlign:"center", flex: "0"}} children={[

                            {type: "Heading1", content: "ranvs"},
                            {type: "Note", content: "static canvases are fun, but randomizing is more fun"},
                            ranvsExampleAnvs,

                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={ranvsExample}  />
                            </div>
                        ]} />

                        <ContentCard key={this.key} className='section' collapsable={collapse(3)} style={{textAlign:"center"}} children={[
                            {type: "Heading1", content: "recursive"},
                            {type: "Note", content: "anvs layers can be recursive, here's a fibonacci sequence"},
                            <AnvsReactLazy.GL 
                                placeholder={loading}
                                key={this.key}
                                size={{x: 800, y: 500}} 
                                seed={1} 
                                resolutionScale={2}
                                dedicatedRenderer={false}
                                layer={this.fib(false)} 
                                style={{maxWidth: "600px", marginTop: "10px", borderRadius: "8px", }} 
                                className={"centered anvs"} 
                            />,
                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={fib}  />
                            </div>
                        ]} />

                        <ContentCard key={this.key} className='section' collapsable={collapse(4)} style={{textAlign:"center"}} children={[
                            {type: "Heading1", content: "updatable"},
                            {type: "Note", content: "layers can be updated through an optional update function"},
                            {type: "Note", content: "here's the same fibonacci sequence but with each layer being rotated in it's update"},
                            <AnvsReactLazy.GL 
                                placeholder={loading}
                                key={this.key}
                                size={{x: 800, y: 500}} 
                                seed={1} 
                                resolutionScale={2}
                                dedicatedRenderer={true}
                                layer={this.fib(true)} 
                                style={{maxWidth: "600px", marginTop: "10px", borderRadius: "8px",}} 
                                className={"centered anvs"} 
                            />,
                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={fibUpdate}  />
                            </div>
                        ]} />


                        <div key={this.key} className='section'>
                            <ContentCard key={this.key} style={{textAlign:"center"}} children={[
                                {type: "Heading1", content: "draw on many canvases"},
                            ]} />
                            <MultipleHeadExample key={this.key} ref={moreRef} className='centered' numberOfHeads={10} />
                            {showMeMoreHeads}
                        </div>

                        <ContentCard key={this.key} className='section' style={{textAlign:"center"}} children={[
                            <button className="bigButton"  key={this.key} onClick={ () => { location.href='/examples';} } > see more examples </button>,
                        ]} />

                        <div className='separator' />

                        <div key={this.key} className='section '>
                            <ContentCard key={this.key} style={{textAlign:"center", flex: "0"}} children={[
                                {type: "Heading1", content: "installation"},
                                {type: "Warning", content: "(coming soon ... maybe)"},
                            ]} />
                        </div>


                        <div className='separator' />




                        <ContentCard key={this.key} className="section" style={{textAlign:"center", flex: "0"}} children={[
                            {type: "Heading1", content: "basic usage"},
                            {type: "Note", content: ""},

                            <div key={this.key} className='syntaxContainer'> 
                                <SyntaxHighlighter key={this.key} language="javascript" style={dracula} children={usage}  />
                            </div>,

                        ]} />
                        
                        <ContentCard key={this.key} className='section' style={{textAlign:"center"}} children={[
                            {type: "Heading1", content: "react"},
                            {type: "Note", content: "built in react component, with a lazy alternative to make ssr happy (for most use cases)"},
                            <div key={this.key} className='centered syntaxContainer' > <SyntaxHighlighter language="jsx" style={dracula} children={reactSmall}  /></div>,
                        ]} />

                        <ContentCard key={this.key} className='section' style={{textAlign:"center"}} children={[
                            <button className="bigButton"  key={this.key} onClick={ () => { location.href='/docs';} } > see full docs </button>,
                        ]} />

                    </div>
                </div>
            </div>
        ]} />
    }
}