import { AnvsShader } from '../../../anvs/Anvs/Core';

var fragment = `precision mediump float;

varying vec2 vTextureCoord;
uniform sampler2D uSampler;

uniform float colors;
uniform float gamma;

vec4 posterize(in vec4 inputColor) {
    vec3 c = inputColor.rgb;
    c = pow(c, vec3(gamma, gamma, gamma));
    c = c * colors;
    c = floor(c);
    c = c / colors;
    c = pow(c, vec3(1.0/gamma));
    
    return vec4(c, inputColor.a);
}

void main()
{
   vec4 color = texture2D(uSampler, vTextureCoord);
   gl_FragColor = posterize(color) * color.a;
}
`


export class Posterize {
    
     static AnvsShader(colors: number = 8): AnvsShader {
        return {
            id: "_AnvsPosterizeShader" + colors.toString() ,
            fragment, 
            uniforms:{gamma: AnvsShader.Uniform.float(1), colors: AnvsShader.Uniform.float(colors),},
        }
    } 
}
