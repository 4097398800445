import eye1 from "../../static/eyes/1.png"
import eye2 from "../../static/eyes/2.png"
import eye3 from "../../static/eyes/3.png"
import eye4 from "../../static/eyes/4.png"
import eye5 from "../../static/eyes/5.png"
import eye6 from "../../static/eyes/6.png"
import eye7 from "../../static/eyes/7.png"
import eye8 from "../../static/eyes/8.png"
import eye9 from "../../static/eyes/9.png"
import eye10 from "../../static/eyes/10.png"
import eye11 from "../../static/eyes/11.png"
import eye12 from "../../static/eyes/12.png"
import eye13 from "../../static/eyes/13.png"
import eye14 from "../../static/eyes/14.png"
import eye15 from "../../static/eyes/15.png"
import eye16 from "../../static/eyes/16.png"
import eye17 from "../../static/eyes/17.png"
import eye18 from "../../static/eyes/18.png"
import eye19 from "../../static/eyes/19.png"
import eye20 from "../../static/eyes/20.png"
import eye21 from "../../static/eyes/21.png"
import eye22 from "../../static/eyes/22.png"
import eye23 from "../../static/eyes/23.png"
import eye24 from "../../static/eyes/24.png"
import eye25 from "../../static/eyes/25.png"
import eye26 from "../../static/eyes/26.png"
import eye27 from "../../static/eyes/27.png"
import eye28 from "../../static/eyes/28.png"
import eye29 from "../../static/eyes/29.png"
import eye30 from "../../static/eyes/30.png"
import eye31 from "../../static/eyes/31.png"
import eye32 from "../../static/eyes/32.png"
import eye33 from "../../static/eyes/33.png"
import eye34 from "../../static/eyes/34.png"
import eye35 from "../../static/eyes/35.png"
import eye36 from "../../static/eyes/36.png"
import eye37 from "../../static/eyes/37.png"
import eye38 from "../../static/eyes/38.png"
import eye39 from "../../static/eyes/39.png"
import eye40 from "../../static/eyes/40.png"

import mouth1 from "../../static/mouths/1.png"
import mouth2 from "../../static/mouths/2.png"
import mouth3 from "../../static/mouths/3.png"
import mouth4 from "../../static/mouths/4.png"
import mouth5 from "../../static/mouths/5.png"
import mouth6 from "../../static/mouths/6.png"
import mouth7 from "../../static/mouths/7.png"
import mouth8 from "../../static/mouths/8.png"
import mouth9 from "../../static/mouths/9.png"
import mouth10 from "../../static/mouths/10.png"
import mouth11 from "../../static/mouths/11.png"
import mouth12 from "../../static/mouths/12.png"
import mouth13 from "../../static/mouths/13.png"
import mouth14 from "../../static/mouths/14.png"
import mouth15 from "../../static/mouths/15.png"
import mouth16 from "../../static/mouths/16.png"
import mouth17 from "../../static/mouths/17.png"
import mouth18 from "../../static/mouths/18.png"
import mouth19 from "../../static/mouths/19.png"
import mouth20 from "../../static/mouths/20.png"

import nose1 from "../../static/noses/1.png"
import nose2 from "../../static/noses/2.png"
import nose3 from "../../static/noses/3.png"
import nose4 from "../../static/noses/4.png"
import nose5 from "../../static/noses/5.png"
import nose6 from "../../static/noses/6.png"
import nose7 from "../../static/noses/7.png"
import nose8 from "../../static/noses/8.png"
import nose9 from "../../static/noses/9.png"
import nose10 from "../../static/noses/10.png"
import nose11 from "../../static/noses/11.png"
import nose12 from "../../static/noses/12.png"
import nose13 from "../../static/noses/13.png"
import nose14 from "../../static/noses/14.png"
import nose15 from "../../static/noses/15.png"
import nose16 from "../../static/noses/16.png"
import nose17 from "../../static/noses/17.png"
import nose18 from "../../static/noses/18.png"
import nose19 from "../../static/noses/19.png"
import nose20 from "../../static/noses/20.png"


export const Eyes = [
    eye1,
    eye2,
    eye3,
    eye4,
    eye5,
    eye6,
    eye7,
    eye8,
    eye9,
    eye10,
    eye11,
    eye12,
    eye13,
    eye14,
    eye15,
    eye16,
    eye17,
    eye18,
    eye19,
    eye20,
    eye21,
    eye22,
    eye23,
    eye24,
    eye25,
    eye26,
    eye27,
    eye28,
    eye29,
    eye30,
    eye31,
    eye32,
    eye33,
    eye34,
    eye35,
    eye36,
    eye37,
    eye38,
    eye39,
    eye40,
]

export const Noses = [
    nose1,
    nose2,
    nose3,
    nose4,
    nose5,
    nose6,
    nose7,
    nose8,
    nose9,
    nose10,
    nose11,
    nose12,
    nose13,
    nose14,
    nose15,
    nose16,
    nose17,
    nose18,
    nose19,
    nose20,
]

export const Mouthes = [
    mouth1,
    mouth2,
    mouth3,
    mouth4,
    mouth5,
    mouth6,
    mouth7,
    mouth8,
    mouth9,
    mouth10,
    mouth11,
    mouth12,
    mouth13,
    mouth14,
    mouth15,
    mouth16,
    mouth17,
    mouth18,
    mouth19,
    mouth20,
]